import React, { Suspense } from 'react'
import { Routes, Route, BrowserRouter } from 'react-router-dom'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const PagamentoLink = React.lazy(() => import('./views/pages/LinkPayments'))

export function App() {
  return (
    <BrowserRouter>
      <Suspense fallback={loading}>
        <Routes>
          <Route
            path="/linkpayments/:codAcesso"
            element={<PagamentoLink />}
          />
           <Route path="*" element={<Page404 />} />
          <Route path="/" element={<Page404 />} />
          <Route path="/404" element={<Page404 />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  )
}
